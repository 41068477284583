import React, { useEffect, useState } from 'react';
import supabase from '../supabaseClient';
import './index_2.css'; // Assuming a separate CSS file for admin styles

const MatchSubmitted = () => {
  const [adminName, setAdminName] = useState('Admin');
  const [matches, setMatches] = useState([]); // Stores confirmed matches
  const [filteredMatches, setFilteredMatches] = useState([]); // Stores filtered matches based on sport
  const [selectedFilter, setSelectedFilter] = useState('All'); // Current filter
  const [error, setError] = useState(''); // Error handling
  const [filterOpen, setFilterOpen] = useState(false); // Dropdown toggle

  // Fetch Admin Name
  useEffect(() => {
    const fetchAdminName = async () => {
      try {
        const { data: session, error: sessionError } = await supabase.auth.getSession();
        if (sessionError || !session.session) {
          setError('Unable to fetch admin session.');
          return;
        }
        const { data: user, error: userError } = await supabase
          .from('Users')
          .select('name')
          .eq('user_id', session.session.user.id)
          .single();
        if (userError) {
          setError('Failed to fetch admin name.');
        } else {
          setAdminName(user.name);
        }
      } catch (err) {
        setError('An unexpected error occurred.');
      }
    };
    fetchAdminName();
  }, []);

  // Fetch Matches with Status Confirmed
  useEffect(() => {
    const fetchConfirmedMatches = async () => {
      try {
        const { data: matchDetails, error } = await supabase
          .from('Match_Details')
          .select('id, user_id, venue, pitch, date_played, hour_slot, sport, status, t_shirt_photo_url, shoes_photo_url,key_code')
          .eq('status', 'confirmed') // Fetch only matches with status confirmed
          .order('date_played', { ascending: false });

        if (error) {
          setError('Failed to fetch match details.');
          return;
        }

        // Enrich matches with user names
        const updatedMatches = await Promise.all(
          matchDetails.map(async (match) => {
            if (match.user_id) {
              const { data: user, error: userError } = await supabase
                .from('Users')
                .select('name')
                .eq('user_id', match.user_id)
                .single();

              if (userError) {
                return { ...match, userName: 'Unknown' };
              }
              return { ...match, userName: user.name };
            }
            return { ...match, userName: 'Unknown' };
          })
        );

        setMatches(updatedMatches);
        setFilteredMatches(updatedMatches);
      } catch (err) {
        setError('An unexpected error occurred.');
      }
    };

    fetchConfirmedMatches();
  }, []);

  // Filter Matches by Sport
  const filterMatches = (sport) => {
    setSelectedFilter(sport);
    setFilterOpen(false); // Close the dropdown after selection
    if (sport === 'All') {
      setFilteredMatches(matches);
    } else {
      setFilteredMatches(matches.filter((match) => match.sport === sport));
    }
  };

  // Handle Stats Update
  const handleUpdateStats = async (matchId, stats) => {
    try {
      // Update Game_Stats with new stats
      const { error: statsError } = await supabase
        .from('Game_Stats')
        .update(stats)
        .eq('match_id', matchId); // Insert or update stats for the match_id

      if (statsError) {
        setError('Failed to update stats.');
        return;
      }

      console.log('Stats updated successfully!');
      setError('');
    } catch (err) {
      setError('An unexpected error occurred.');
    }
  };

  // Match Item Component
  const MatchItem = ({ match }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [stats, setStats] = useState({
      goals: 0,
      assists: 0,
      shots: 0,
      shots_on_target: 0,
      Total_Tackles: 0,
      tackles_won: 0,
      Total_Dribbles: 0,
      succesful_dribbles: 0,
      highlights: '', // Include highlights field
    });

    // Fetch current stats when entering edit mode
    useEffect(() => {
      const fetchStats = async () => {
        try {
          const { data: gameStats, error } = await supabase
            .from('Game_Stats')
            .select('*')
            .eq('match_id', match.id)
            .single();

          if (error) {
            console.log('Failed to fetch current stats:', error.message);
            return;
          }

          if (gameStats) {
            setStats(gameStats); // Set the current stats if they exist
          }
        } catch (err) {
          console.error('Error fetching stats:', err);
        }
      };

      if (isEditing) {
        fetchStats(); // Fetch stats only when editing mode starts
      }
    }, [isEditing, match.id]);

    const handleChange = (e) => {
      const { name, value } = e.target;
      setStats({
        ...stats,
        [name]: name === 'highlights' ? value : parseInt(value, 10) >= 0 ? parseInt(value, 10) : 0,
      });
    };

    const handleSubmit = async (e) => {
      e.preventDefault();
      await handleUpdateStats(match.id, stats);
      setIsEditing(false); // Exit editing mode after submission
    };

    return (
      <div className="match-item">
        {!isEditing ? (
          <>
            <div className="match-venue">
              {match.venue} - <span className="match-pitch">{match.pitch || 'Unknown Pitch'}</span>
            </div>
            <div className="match-details">
              <span>{match.date_played}</span> | <span>{match.hour_slot}</span>
            </div>
            <div className="match-sport">{match.sport}</div>

            {/* Display User ID */}
            <div className="match-user-info">
  <span>
    <strong>User ID:</strong> {match.user_id || 'N/A'}
  </span>
  <br />
  <span>
    <strong>Key Code:</strong> {match.key_code || 'N/A'}
  </span>
</div>


            <button className="complete-stats-button" onClick={() => setIsEditing(true)}>
              Edit Stats
            </button>
          </>
        ) : (
          <div className="match-editing-container">
            {/* Stats Form */}
            <form onSubmit={handleSubmit} className="admin-stats-form">
              <label className="form-label">
                Goals:
                <input
                  className="form-input"
                  type="number"
                  name="goals"
                  value={stats.goals}
                  onChange={handleChange}
                />
              </label>
              <label className="form-label">
                Assists:
                <input
                  className="form-input"
                  type="number"
                  name="assists"
                  value={stats.assists}
                  onChange={handleChange}
                />
              </label>
              <label className="form-label">
                Shots:
                <input
                  className="form-input"
                  type="number"
                  name="shots"
                  value={stats.shots}
                  onChange={handleChange}
                />
              </label>
              <label className="form-label">
                Shots on Target:
                <input
                  className="form-input"
                  type="number"
                  name="shots_on_target"
                  value={stats.shots_on_target}
                  onChange={handleChange}
                />
              </label>
              <label className="form-label">
                Total Tackles:
                <input
                  className="form-input"
                  type="number"
                  name="Total_Tackles"
                  value={stats.Total_Tackles}
                  onChange={handleChange}
                />
              </label>
              <label className="form-label">
                Tackles Won:
                <input
                  className="form-input"
                  type="number"
                  name="tackles_won"
                  value={stats.tackles_won}
                  onChange={handleChange}
                />
              </label>
              <label className="form-label">
                Total Dribbles:
                <input
                  className="form-input"
                  type="number"
                  name="Total_Dribbles"
                  value={stats.Total_Dribbles}
                  onChange={handleChange}
                />
              </label>
              <label className="form-label">
                Successful Dribbles:
                <input
                  className="form-input"
                  type="number"
                  name="succesful_dribbles"
                  value={stats.succesful_dribbles}
                  onChange={handleChange}
                />
              </label>
              <label className="form-label">
                Highlight Link:
                <input
                  className="form-input"
                  type="text"
                  name="highlights"
                  value={stats.highlights}
                  onChange={handleChange}
                  placeholder="Enter highlight link (optional)"
                />
              </label>

              <div className="form-buttons">
                <button className="submit-button" type="submit">
                  Update Stats
                </button>
                <button className="cancel-button" type="button" onClick={() => setIsEditing(false)}>
                  Cancel
                </button>
              </div>
            </form>

            {/* Display Photos and Details */}
            <div className="match-photos">
              <div className="photo-info">
                <h3>Match Details</h3>
                <p><strong>Venue:</strong> {match.venue}</p>
                <p><strong>Pitch:</strong> {match.pitch}</p>
                <p><strong>Date:</strong> {match.date_played}</p>
                <p><strong>Time:</strong> {match.hour_slot}</p>
              </div>
              {match.t_shirt_photo_url && (
                <img src={match.t_shirt_photo_url} alt="T-Shirt" className="photo t-shirt-photo" />
              )}
              {match.shoes_photo_url && (
                <img src={match.shoes_photo_url} alt="Shoes" className="photo shoes-photo" />
              )}
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="match-submissions-container">
      <h1 className="page-title">Match Submitted</h1>
      <div className="admin-header">
        <h2>Welcome, {adminName}</h2>
      </div>

      {error && <div className="error-message">{error}</div>}

      {/* Filter Dropdown */}
      <div className="filter-dropdown" onClick={() => setFilterOpen(!filterOpen)}>
        <span>{selectedFilter}</span>
        {filterOpen && (
          <div className="filter-options">
            {['All', 'Football', 'Basketball'].map((sport) => (
              <div
                key={sport}
                className="filter-option"
                onClick={() => filterMatches(sport)}
              >
                {sport}
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Match List */}
      <div className="matches-list">
        {filteredMatches.map((match) => (
          <MatchItem key={match.id} match={match} />
        ))}
      </div>
    </div>
  );
};

export default MatchSubmitted;
