import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import supabase from '../supabaseClient';
import './index_2.css';

const Sidebar = () => {
  const [adminName, setAdminName] = useState('Admin');

  // Fetch admin details from Supabase
  useEffect(() => {
    const fetchAdminData = async () => {
      try {
        const { data: sessionData } = await supabase.auth.getSession();
        if (sessionData?.session?.user) {
          const userId = sessionData.session.user.id;
          const { data: userData, error } = await supabase
            .from('Users')
            .select('name')
            .eq('user_id', userId)
            .single();

          if (error) throw error;
          setAdminName(userData.name || 'Admin');
        }
      } catch (error) {
        console.error('Error fetching admin data:', error.message);
      }
    };

    fetchAdminData();
  }, []);

  return (
    <div className="sidebar">
      <div className="sidebar-header">
        <span className="sidebar-name">{adminName}</span>
      </div>
      <ul className="sidebar-menu">
        <li>
          <Link to="/admin/match-submissions">Match Submissions</Link>
        </li>
        <li>
          <Link to="/admin/match-submitted">Match Submitted</Link>
        </li>
        <li>
          <Link to="/admin/dashboard">Dashboard</Link>
        </li>
      </ul>
      <div className="sidebar-footer">
        <Link to="/admin/settings">Settings</Link>
      </div>
    </div>
  );
};

export default Sidebar;
