import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// User Components
import Login from './Login';
import CreateProfile from './CreateProfile';
import Profile from './Profile';
import ConfirmEmail from './ConfirmEmail';
import Settings from './Settings';
import SubmitMatch from './SubmitMatch';
import ResetPassword from './ResetPassword';

// Admin Components
import AdminLayout from './components/AdminLayout';
import Dashboard from './components/Dashboard';
import MatchSubmissions from './components/MatchSubmissions';
import MatchSubmitted from './components/MatchSubmitted';

// Import ProtectedRoute for user authentication
import ProtectedRoute from './ProtectedRoute';

// Import AdminRoute for admin route protection
import AdminRoute from './components/AdminRoute';

const App = () => {
  return (
    <Router>
      <div className="container_app">
        <Routes>
          {/* Public Routes */}
          <Route path="/" element={<Login />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/confirm-email" element={<ConfirmEmail />} />

          {/* Protected Routes */}
          <Route
            path="/create-profile"
            element={
              <ProtectedRoute>
                <CreateProfile />
              </ProtectedRoute>
            }
          />
          <Route
            path="/profile"
            element={
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>
            }
          />
          <Route
            path="/submitMatch"
            element={
              <ProtectedRoute>
                <SubmitMatch />
              </ProtectedRoute>
            }
          />
          <Route
            path="/settings"
            element={
              <ProtectedRoute>
                <Settings />
              </ProtectedRoute>
            }
          />

          {/* Admin Routes */}
          <Route
            path="/admin"
            element={
              <AdminRoute>
                <AdminLayout />
              </AdminRoute>
            }
          >
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="match-submissions" element={<MatchSubmissions />} />
            <Route path="match-submitted" element={<MatchSubmitted />} />
          </Route>
        </Routes>
      </div>
    </Router>
  );
};

export default App;
