import React, { useState } from 'react';
import supabase from './supabaseClient';
import { useNavigate } from 'react-router-dom';
import './settings.css'; // Renamed the CSS file for clarity

export default function Settings() {
  const [error, setError] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  // Handle user logout
  const handleLogout = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) {
      setError('Failed to log out: ' + error.message);
    } else {
      navigate('/');
    }
  };

  // Handle password change
  const handleChangePassword = async () => {
    if (!newPassword || !confirmPassword) {
      setError('Please fill out both password fields.');
      return;
    }

    if (newPassword !== confirmPassword) {
      setError('Passwords do not match. Please try again.');
      return;
    }

    const { error } = await supabase.auth.updateUser({ password: newPassword });
    if (error) {
      setError('Failed to change password: ' + error.message);
    } else {
      alert('Password changed successfully!');
      setNewPassword('');
      setConfirmPassword('');
    }
  };

  return (
    <div className="settings-container">
      <button className="back-button" onClick={() => navigate('/profile')}>
        ←
      </button>
      <h2 className="settings-title">Settings</h2>

      <h3 className="settings-subtitle">Change Password</h3>
      <input
        type={showPassword ? 'text' : 'password'}
        className="settings-input"
        placeholder="New Password"
        value={newPassword}
        onChange={(e) => setNewPassword(e.target.value)}
      />
      <input
        type={showPassword ? 'text' : 'password'}
        className="settings-input"
        placeholder="Confirm New Password"
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
      />
      <div className="show-password-container">
        <input
          type="checkbox"
          id="showPassword"
          checked={showPassword}
          onChange={() => setShowPassword(!showPassword)}
        />
        <label htmlFor="showPassword">Show Password</label>
      </div>
      <button className="settings-button" onClick={handleChangePassword}>
        Modify Password
      </button>
      <button className="settings-button logout-button" onClick={handleLogout}>
        Log Out
      </button>
      {error && <p className="error-message">{error}</p>}
    </div>
  );
}
