import React, { useState, useEffect } from 'react';
import supabase from './supabaseClient';
import { useNavigate } from 'react-router-dom';
import './submit-match.css';

export default function SubmitMatch() {
  const [venue, setVenue] = useState('');
  const [pitch, setPitch] = useState('');
  const [sport, setSport] = useState('');
  const [hourSlot, setHourSlot] = useState('');
  const [date, setDate] = useState('');
  const [tShirtPhoto, setTShirtPhoto] = useState(null);
  const [shoesPhoto, setShoesPhoto] = useState(null);
  const [keyCode, setKeyCode] = useState('');
  const [promoCode, setPromoCode] = useState('');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [underReviewCount, setUnderReviewCount] = useState(0);
  const navigate = useNavigate();

  // Fetch user session and matches under review on component load
  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: sessionData, error: sessionError } = await supabase.auth.getSession();
        if (sessionError || !sessionData?.session) {
          setError('Session expired. Please log in again.');
          navigate('/login');
          return;
        }

        const user = sessionData.session.user;

        // Fetch count of matches under review
        const { count, error: countError } = await supabase
          .from('Match_Details')
          .select('*', { count: 'exact' })
          .eq('user_id', user.id)
          .eq('status', 'under_review');

        if (countError) {
          setError('Failed to fetch matches under review: ' + countError.message);
        } else {
          setUnderReviewCount(count || 0); // Update the count
        }
      } catch (err) {
        setError('An unexpected error occurred: ' + err.message);
      }
    };

    fetchData();
  }, [navigate]);

  // Handle file uploads
  const handleTShirtPhotoUpload = (e) => {
    const file = e.target.files[0];
    if (file) setTShirtPhoto(file);
  };

  const handleShoesPhotoUpload = (e) => {
    const file = e.target.files[0];
    if (file) setShoesPhoto(file);
  };

  // Sanitize file name by replacing spaces and special characters
  const sanitizeFileName = (fileName) => {
    return fileName.replace(/[^a-zA-Z0-9.-]/g, '');
  };

  // Upload a photo to Supabase Storage
  const uploadPhoto = async (file, folder) => {
    try {
      const sanitizedFileName = sanitizeFileName(file.name);
      const fileName = `${folder}/${Date.now()}_${sanitizedFileName}`;
      const { data, error } = await supabase.storage
        .from('match_photos')
        .upload(fileName, file);

      if (error) throw new Error(`Photo upload failed for ${folder}: ${error.message}`);

      const { data: urlData, error: urlError } = supabase.storage
        .from('match_photos')
        .getPublicUrl(fileName);

      if (urlError) throw new Error(`Failed to retrieve photo URL for ${folder}: ${urlError.message}`);

      return urlData.publicUrl;
    } catch (err) {
      throw new Error(err.message);
    }
  };

  // Handle match submission
  const handleSubmitMatch = async (e) => {
    e.preventDefault();

    if (!venue || !pitch || !sport || !hourSlot || !date) {
      setError('Please fill in all fields.');
      return;
    }

    try {
      let tShirtPhotoUrl = null;
      let shoesPhotoUrl = null;

      if (tShirtPhoto) {
        tShirtPhotoUrl = await uploadPhoto(tShirtPhoto, 'tShirtPhotos');
      }

      if (shoesPhoto) {
        shoesPhotoUrl = await uploadPhoto(shoesPhoto, 'shoesPhotos');
      }

      const user = (await supabase.auth.getUser()).data.user;
      if (!user) {
        setError('User not authenticated. Please log in.');
        navigate('/login');
        return;
      }

      // Check if the user has reached the maximum number of matches under review
      if (underReviewCount >= 3) {
        setError(
          `You currently have ${underReviewCount} matches under review which is the maximum. Please wait for results or delete one to submit a new match.`
        );
        return;
      }

      // Insert match details
      const { data: match, error: matchError } = await supabase
        .from('Match_Details')
        .insert([
          {
            user_id: user.id,
            sport,
            date_played: date,
            hour_slot: hourSlot,
            venue,
            pitch,
            key_code: keyCode,
            promo_code: promoCode,
            t_shirt_photo_url: tShirtPhotoUrl,
            shoes_photo_url: shoesPhotoUrl,
            status: 'under_review',
          },
        ])
        .select()
        .single();

      if (matchError) {
        setError('Failed to submit match: ' + matchError.message);
        return;
      }

      // Insert corresponding game stats
      const { error: statsError } = await supabase
        .from('Game_Stats')
        .insert([
          {
            match_id: match.id,
            user_id: user.id,
            goals: 0,
            shots: 0,
            shots_on_target: 0,
            assists: 0,
            Total_Dribbles: 0,
            succesful_dribbles: 0,
            Total_Tackles: 0,
            tackles_won: 0,
          },
        ]);

      if (statsError) {
        setError('Failed to create game stats: ' + statsError.message);
        return;
      }

      setMessage('Match and stats submitted successfully!');
      navigate('/profile');
    } catch (err) {
      setError('An unexpected error occurred: ' + err.message);
    }
  };

  // Generate time slots
  const generateTimeSlots = () => {
    const slots = [];
    for (let hour = 0; hour < 24; hour++) {
      const hourStr = hour.toString().padStart(2, '0');
      slots.push(`${hourStr}:00`);
      slots.push(`${hourStr}:30`);
    }
    return slots;
  };

  const timeSlots = generateTimeSlots();

  return (
    <div className="submit-match-container">
      <h2>Submit Match</h2>

      <button className="back-button" onClick={() => navigate('/profile')}>
      ←
      </button>

      <form onSubmit={handleSubmitMatch}>
        <select value={venue} onChange={(e) => setVenue(e.target.value)} required>
          <option value="">Select Venue</option>
          <option value="Sports Zone">Sports Zone</option>
        </select>

        <select value={pitch} onChange={(e) => setPitch(e.target.value)} required>
          <option value="">Select Pitch</option>
          <option value="Pitch 1">Pitch 1</option>
          <option value="Pitch 2">Pitch 2</option>
          <option value="Pitch 3">Pitch 3</option>
        </select>

        <select value={sport} onChange={(e) => setSport(e.target.value)} required>
          <option value="">Select Sport</option>
          <option value="Football">Football</option>
        </select>

        <select value={hourSlot} onChange={(e) => setHourSlot(e.target.value)} required>
          <option value="">Select Time Slot</option>
          {timeSlots.map((slot) => (
            <option key={slot} value={slot}>
              {slot}
            </option>
          ))}
        </select>

        <input type="date" value={date} onChange={(e) => setDate(e.target.value)} required />

        <div className="form-group">
          <label>Key Code:</label>
          <input type="text" value={keyCode} onChange={(e) => setKeyCode(e.target.value)} />
        </div>

        <div className="form-group">
          <label>Promo Code:</label>
          <input type="text" value={promoCode} onChange={(e) => setPromoCode(e.target.value)} />
        </div>

        <div className="form-group">
          <label>T-shirt Photo:</label>
          <input type="file" onChange={handleTShirtPhotoUpload} />
        </div>

        <div className="form-group">
          <label>Shoes Photo:</label>
          <input type="file" onChange={handleShoesPhotoUpload} />
        </div>

        <button type="submit">Submit Match</button>
      </form>

      {underReviewCount >= 3 && (
        <p className="notice-message">
          You currently have {underReviewCount} matches under review which is the maximum. Please wait for results or delete one to submit a new match.
        </p>
      )}

      {error && <p className="error-message">{error}</p>}
      {message && <p className="success-message">{message}</p>}
    </div>
  );
}
