import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import supabase from './supabaseClient';

const ProtectedRoute = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  useEffect(() => {
    const checkSession = async () => {
      const { data, error } = await supabase.auth.getSession();

      if (error || !data.session) {
        // User is not authenticated
        setIsAuthenticated(false);
      } else {
        // User is authenticated
        setIsAuthenticated(true);
      }
    };

    checkSession();
  }, []);

  // Show a loading screen while checking authentication
  if (isAuthenticated === null) {
    return <div>Loading...</div>;
  }

  // Redirect to login if not authenticated
  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  // Render the protected content if authenticated
  return children;
};

export default ProtectedRoute;
