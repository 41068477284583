import React, { useState, useEffect } from 'react';
import supabase from './supabaseClient';
import { useNavigate } from 'react-router-dom';
import './login.css';

export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [showSignup, setShowSignup] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();

  // Check if the user is already logged in
  useEffect(() => {
    const checkSession = async () => {
      const { data, error } = await supabase.auth.getSession();

      if (data?.session) {
        // Redirect to the profile page if the user is already logged in
        navigate('/profile');
      }
    };

    checkSession();
  }, [navigate]);

  // Handle user login
  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const { data: signInData, error: signInError } = await supabase.auth.signInWithPassword({
        email,
        password,
      });

      if (signInError || !signInData || !signInData.user) {
        setError('Invalid login credentials');
        return;
      }

      const { user } = signInData;

      // Check if the user has a profile in the Users table
      const { data: userProfile, error: profileError } = await supabase
        .from('Users')
        .select('*')
        .eq('user_id', user.id)
        .single();

      if (profileError && profileError.code !== 'PGRST116') {
        setError('Error retrieving user profile');
        return;
      }

      // Redirect based on profile and admin status
      if (!userProfile) {
        navigate('/create-profile');
      } else if (userProfile.admin_Pass && userProfile.admin_Pass.trim() !== '') {
        navigate('/admin/match-submissions');
      } else {
        navigate('/profile');
      }
    } catch (err) {
      setError(err.message || 'An unexpected error occurred.');
    }
  };

  // Handle forgot password
  const handleForgotPassword = async (e) => {
    e.preventDefault();
    try {
      const { error } = await supabase.auth.resetPasswordForEmail(email, {
        redirectTo: 'https://www.statbox.co/reset-password',
      });

      if (error) {
        setError('Failed to send reset password email: ' + error.message);
        return;
      }

      setSuccessMessage('Password reset email sent successfully!');
      setShowForgotPassword(false);
    } catch (err) {
      setError('An unexpected error occurred: ' + err.message);
    }
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
  
    if (password !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }
  
    try {
      // Call the RPC to check if the email exists
      const { data: checkResponse, error: checkError } = await supabase.rpc('check_email_exists', {
        email_to_check: email,
      });
  
      console.log('RPC Response:', checkResponse); // Debugging response
  
      if (checkError) {
        setError('Error checking email: ' + checkError.message);
        return;
      }
  
      // Handle the case where the response is an array
      const emailCheck = checkResponse[0]; // Access the first element of the array
      if (!emailCheck) {
        setError('Unexpected response from server.');
        return;
      }
  
      if (emailCheck.email_exists) {
        if (emailCheck.is_verified) {
          setError('This email is already registered. Please log in.');
        } else {
          setError(
            'This email is registered but not verified. Please check your inbox or click below to resend the verification email.'
          );
        }
      }
      
  
      // Proceed with the sign-up if email does not exist
      const { data, error } = await supabase.auth.signUp({
        email,
        password,
        options: {
          emailRedirectTo: 'https://www.statbox.co/create-profile',
        },
      });
  
      if (error) {
        setError('Sign-up failed: ' + error.message);
        return;
      }
  
      setSuccessMessage('Sign-up successful! Please confirm your email.');
      navigate('/confirm-email');
    } catch (err) {
      setError('An unexpected error occurred: ' + err.message);
    }
  };
  
  

  const resendVerificationEmail = async () => {
    try {
      const { data, error } = await supabase.auth.signUp({
        email,
        password: 'temporaryPassword', // Use a dummy password; it will not overwrite anything
      });
  
      if (error) {
        setError('Failed to resend verification email: ' + error.message);
        return;
      }
  
      setSuccessMessage('Verification email resent successfully. Please check your inbox.');
    } catch (err) {
      setError('An unexpected error occurred: ' + err.message);
    }
  };  
  

  return (
    <div className="form-container">
      {showForgotPassword ? (
        <>
          <h2>Forgot Password</h2>
          <form onSubmit={handleForgotPassword}>
            <div className="form-group">
              <input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <button type="submit">Send Reset Email</button>
          </form>
          {error && <p className="error-message">{error}</p>}
          {successMessage && <p className="success-message">{successMessage}</p>}
          <p>
            Remembered your password? <a onClick={() => setShowForgotPassword(false)}>Go Back to Login</a>
          </p>
        </>
      ) : showSignup ? (
        <>
    <h2>Sign Up</h2>
    <form onSubmit={handleSignUp}>
      <div className="form-group">
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
      </div>
      <div className="form-group">
        <input
          type={showPassword ? 'text' : 'password'}
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
      </div>
      <div className="form-group">
        <input
          type={showPassword ? 'text' : 'password'}
          placeholder="Confirm Password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required
        />
      </div>
      <div className="form-group">
        <label>
          <input
            type="checkbox"
            checked={showPassword}
            onChange={() => setShowPassword(!showPassword)}
          />
          Show Passwords
        </label>
      </div>
      <button type="submit">Sign Up</button>
    </form>
    {error && (
      <div className="error-section">
        <p className="error-message">{error}</p>
        {error.includes('registered but not verified') && (
          <button onClick={resendVerificationEmail} className="resend-btn">
            Resend Verification Email
          </button>
        )}
      </div>
    )}
    <p>
      Already have an account? <a onClick={() => setShowSignup(false)}>Login</a>
    </p>
  </>
      ) : (
        <>
          <h2>Welcome</h2>
          <div className="logo-container">
            <img src="/Statbox_logo_new.jpg" alt="Loading Logo" className="loading-logo" />
          </div>
          <form onSubmit={handleLogin}>
            <div className="form-group">
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>

            <div className="form-group">
              <input
                type={showPassword ? 'text' : 'password'}
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label>
                <input
                  type="checkbox"
                  checked={showPassword}
                  onChange={() => setShowPassword(!showPassword)}
                />
                Show Password
              </label>
            </div>
            <button type="submit">Log In</button>
          </form>
          {error && <p className="error-message">{error}</p>}
          <p>
            <a onClick={() => setShowForgotPassword(true)}>Forgot Password?</a>
          </p>
          <p>
            Don't have an account? <a onClick={() => setShowSignup(true)}>Sign Up</a>
          </p>
        </>
      )}
    </div>
  );
}