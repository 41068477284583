import React, { useState, useEffect } from 'react';
import supabase from '../supabaseClient';
import './index_2.css';

const Dashboard = () => {
  const [totalMatches, setTotalMatches] = useState(0); // Total confirmed matches
  const [uniquePlayers, setUniquePlayers] = useState(0); // Number of unique players
  const [averageRevenue, setAverageRevenue] = useState(0); // Average revenue calculation
  const [error, setError] = useState(''); // Error handling

  const fetchReportData = async () => {
    try {
      setError(''); // Reset errors before making a request

      // Fetch total confirmed matches for "Sports Zone"
      const { count: matchCount, error: matchError } = await supabase
        .from('Match_Details')
        .select('id', { count: 'exact', head: true })
        .eq('venue', 'Sports Zone')
        .eq('status', 'confirmed');

      if (matchError) {
        setError('Failed to fetch total matches.');
        return;
      }

      // Fetch unique players who played in confirmed matches this month
      const { data: players, error: playerError } = await supabase
        .from('Match_Details')
        .select('user_id', { distinct: true })
        .eq('venue', 'Sports Zone')
        .eq('status', 'confirmed')
        .gte('date_played', new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString()); // First day of the current month

      if (playerError) {
        setError('Failed to fetch unique players.');
        return;
      }

      // Update state with the fetched data
      setTotalMatches(matchCount || 0);
      setUniquePlayers(players.length);
      setAverageRevenue((matchCount || 0) * 3); // $3 per game
    } catch (err) {
      setError('An unexpected error occurred. Please try again.');
    }
  };

  useEffect(() => {
    fetchReportData(); // Automatically generate report on load
  }, []);

  const handleGenerateReport = () => {
    fetchReportData(); // Regenerate report on button click
  };

  return (
    <div className="dashboard">
      <h1>Dashboard</h1>
      <div className="dashboard-controls">
        <select value="Sports Zone" disabled>
          {/* Dropdown limited to "Sports Zone" */}
          <option>Sports Zone</option>
        </select>
        <button className="generate-report-btn" onClick={handleGenerateReport}>
          Generate Report
        </button>
      </div>

      {/* Error message */}
      {error && <div className="error-message">{error}</div>}

      {/* Display Report */}
      {!error && (
        <div className="dashboard-report">
          <h2>Report for Sports Zone</h2>
          <p><strong>Total Confirmed Matches:</strong> {totalMatches}</p>
          <p><strong>Unique Players This Month:</strong> {uniquePlayers}</p>
          <p><strong>Average Revenue (at $3/game):</strong> ${averageRevenue}</p>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
