import React, { useState, useEffect } from 'react';
  import supabase from './supabaseClient';
  import { useNavigate } from 'react-router-dom';
  import { Settings, ChevronDown } from 'react-feather';
  import './profile.css';


  export default function Profile() {
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState('profile');
    const [filterOpen, setFilterOpen] = useState(false);
    const [profileData, setProfileData] = useState(null);
    const [selectedMatch, setSelectedMatch] = useState(null);
    const [error, setError] = useState('');
    const [activeStatTab, setActiveStatTab] = useState('Total');
    const [matchCount, setMatchCount] = useState(0);
    const [rank, setRank] = useState('');
    const [progress, setProgress] = useState(0);
    const [matches, setMatches] = useState([]); // To store matches from Supabase
    const [matchStats, setMatchStats] = useState(null); // To store stats for a selected match
    const [filteredMatches, setFilteredMatches] = useState([]);
    const [selectedFilter, setSelectedFilter] = useState('All');
    const [highlights, setHighlights] = useState([]);
    const [refreshKey, setRefreshKey] = useState(0);




    // Mock matches data
    const matchesData = [
      { venue: 'Sports Zone', date: '28th Aug 2024', sport: 'Football' },
      { venue: 'City Arena', date: '15th Sep 2024', sport: 'Basketball' },
    ];

    // Mock stats data
    const matchStatsData = {
      shooting: {
        title: 'Shooting',
        stats: [
          { label: 'Goals', value: 1 },
          { label: 'Shots', value: 4 },
          { label: 'Shots on Target', value: 2 },
        ],
      },
      passing: {
        title: 'Passing',
        stats: [{ label: 'Assists', value: 2 }],
      },
      possession: {
        title: 'Possession',
        stats: [
          { label: 'Successful Dribbles', value: 4 },
          { label: 'Successful Dribbles %', value: '42%' },
        ],
      },
      defending: {
        title: 'Defending',
        stats: [
          { label: 'Tackles won', value: 8 },
          { label: 'Tackles won %', value: '45%' },
        ],
      },
    };


    // Fetch user data from Supabase
    useEffect(() => {
      const fetchUserData = async () => {
        try {
          const { data: { user }, error: authError } = await supabase.auth.getUser();
          if (authError || !user) {
            setError('User not authenticated.');
            return;
          }
    
          // Fetch profile data
          const { data, error } = await supabase
            .from('Users')
            .select('*')
            .eq('user_id', user.id)
            .single();
    
          if (error) {
            setError('Failed to fetch profile data: ' + error.message);
          } else {
            setProfileData(data);
          }
    
          // Fetch count of confirmed matches for the user
  const { count, error: matchCountError } = await supabase
  .from('Match_Details')
  .select('*', { count: 'exact' })
  .eq('user_id', user.id)
  .eq('status', 'confirmed');

  if (matchCountError) {
  setError('Failed to fetch match count: ' + matchCountError.message);
  } else {
  setMatchCount(count || 0); // Update match count
  calculateRankAndProgress(count || 0); // Calculate gamification rank and progress
  }
    
          // Fetch match details
          const { data: matchDetails, error: matchesError } = await supabase
  .from('Match_Details')
  .select('*')
  .eq('user_id', user.id)
  .order('date_played', { ascending: false })
  .order('hour_slot', { ascending: false });

if (matchesError) {
  setError('Failed to fetch match details: ' + matchesError.message);
} else {
  // Ensure state is updated properly even if no matches are found
  setMatches(matchDetails || []);
  setFilteredMatches(matchDetails || []);
}

        } catch (err) {
          setError('An unexpected error occurred.');
          console.error(err);
        }
      };
    
      fetchUserData(); // Initial data fetch
      fetchHighlights();
    }, [navigate, refreshKey]);    
    
    // Log matches state changes
  useEffect(() => {
    console.log('Matches state updated:', matches);
  }, [matches]);

  const fetchMatchStats = async (matchId, userId) => {
    try {
      // Fetch the match stats from Supabase
      const { data: stats, error } = await supabase
  .from('Game_Stats')
  .select('*')
  .eq('match_id', matchId)
  .eq('user_id', userId)
  .single();

if (error) {
  setError(`Failed to fetch match stats: ${error.message}`);
  return;
}

      // Ensure all stats are initialized
      const totalDribbles = stats.Total_Dribbles || 0;
      const successfulDribbles = stats.succesful_dribbles || 0;
      const totalTackles = stats.Total_Tackles || 0;
      const successfulTackles = stats.tackles_won || 0;

      // Calculate percentages with proper checks
      const successfulDribblesPercentage =
  totalDribbles > 0
    ? `${((successfulDribbles / totalDribbles) * 100).toFixed(2)}%`
    : '0%';

const tacklesWonPercentage =
  totalTackles > 0
    ? `${((successfulTackles / totalTackles) * 100).toFixed(2)}%`
    : '0%';

      // Create updated stats object with percentages
      const updatedStats = {
        ...stats,
        Successful_Dribbles_Percentage: successfulDribblesPercentage,
        Tackles_Won_Percentage: tacklesWonPercentage,
      };

      // Update the match stats state
      setMatchStats(updatedStats);
    } catch (err) {
      setError('An unexpected error occurred while fetching stats.');
      console.error(err);
    }
  };

  const handleMatchClick = async (match) => {
    try {
      setSelectedMatch(match); // Set the selected match
      await fetchMatchStats(match.id, profileData?.user_id); // Fetch stats for the selected match
    } catch (error) {
      setError('Failed to load match stats.');
    }
  };

  

  const fetchTotalStats = async (userId) => {
    try {
      const { data: stats, error } = await supabase
        .from('Game_Stats')
        .select('*')
        .eq('user_id', userId)
        .in('match_id', (await supabase
          .from('Match_Details')
          .select('id')
          .eq('user_id', userId)
          .eq('status', 'confirmed')
        ).data.map(match => match.id)); // Filter stats for confirmed matches

      if (error) {
        setError('Failed to fetch total stats: ' + error.message);
        return;
      }

      if (!stats || stats.length === 0) {
        setMatchStats([]); // Set an empty array if no stats are available
        return;
      }

      const totals = stats.reduce(
        (acc, game) => {
          acc.totalDribbles += game.Total_Dribbles || 0;
          acc.successfulDribbles += game.succesful_dribbles || 0;
          acc.totalTackles += game.Total_Tackles || 0;
          acc.successfulTackles += game.tackles_won || 0;
          acc.goals += game.goals || 0;
          acc.shots += game.shots || 0;
          acc.shotsOnTarget += game.shots_on_target || 0;
          acc.assists += game.assists || 0;
          acc.gamesCount += 1;
          return acc;
        },
        {
          totalDribbles: 0,
          successfulDribbles: 0,
          totalTackles: 0,
          successfulTackles: 0,
          goals: 0,
          shots: 0,
          shotsOnTarget: 0,
          assists: 0,
          gamesCount: 0,
        }
      );

      const successfulDribblesPercentage = totals.totalDribbles
        ? ((totals.successfulDribbles / totals.totalDribbles) * 100).toFixed(2) + '%'
        : '0%';
      const tacklesWonPercentage = totals.totalTackles
        ? ((totals.successfulTackles / totals.totalTackles) * 100).toFixed(2) + '%'
        : '0%';

      setMatchStats({
        ...totals,
        successfulDribblesPercentage,
        tacklesWonPercentage,
      });
    } catch (err) {
      setError('An unexpected error occurred while fetching total stats.');
      console.error(err);
    }
  };
    

    // Calculate rank and progress
    const calculateRankAndProgress = (count) => {
      let userRank = '';
      let userProgress = 0;
    
      if (count <= 15) {
        userRank = `Bronze (Level ${Math.ceil(count / 5)})`;
        userProgress = (count % 5) * 20;
      } else if (count <= 30) {
        userRank = `Silver (Level ${Math.ceil((count - 15) / 5)})`;
        userProgress = ((count - 15) % 5) * 20;
      } else if (count <= 45) {
        userRank = `Gold (Level ${Math.ceil((count - 30) / 5)})`;
        userProgress = ((count - 30) % 5) * 20;
      } else if (count <= 60) {
        userRank = `Platinum (Level ${Math.ceil((count - 45) / 5)})`;
        userProgress = ((count - 45) % 5) * 20;
      } else {
        userRank = `Legend (Level ${Math.ceil((count - 60) / 5)})`;
        userProgress = ((count - 60) % 5) * 20;
      }
    
      setRank(userRank);
      setProgress(userProgress);
    };

    const fetchHighlights = async () => {
      try {
        const { data: authData, error: authError } = await supabase.auth.getUser();
        if (authError || !authData?.user) {
          console.error('Error fetching user:', authError?.message || 'No user found');
          return;
        }
    
        const userId = authData.user.id;
    
        // Fetch data by joining Game_Stats and Match_Details
        const { data: highlightsData, error: highlightsError } = await supabase
          .from('Game_Stats')
          .select(`
            highlights,
            match_id,
            Match_Details (venue, pitch, date_played, hour_slot, sport)
          `)
          .eq('user_id', userId) // Filter by user ID
          .not('highlights', 'is', null) // Exclude NULL highlights
          .neq('highlights', 'EMPTY'); // Exclude rows marked as EMPTY
    
        if (highlightsError) {
          console.error('Error fetching highlights:', highlightsError.message);
        } else {
          // Filter only highlights that are valid (not "Not Available")
          const validHighlights = highlightsData.filter(
            (highlight) => highlight.highlights && highlight.highlights !== 'Not Available'
          );
    
          // Format the data
          const formattedHighlights = validHighlights.map((highlight) => ({
            highlights: highlight.highlights,
            venue: highlight.Match_Details?.venue || 'N/A',
            pitch: highlight.Match_Details?.pitch || 'N/A',
            datePlayed: highlight.Match_Details?.date_played || 'N/A',
            hourSlot: highlight.Match_Details?.hour_slot || 'N/A',
            sport: highlight.Match_Details?.sport || 'N/A',
          }));
    
          setHighlights(formattedHighlights); // Store formatted data in state
        }
      } catch (err) {
        console.error('An unexpected error occurred while fetching highlights.', err);
      }
    };        
    
    
    

    const handleTabClick = async (tab) => {
      setActiveTab(tab);
      if (tab === 'stats') {
        await fetchTotalStats(profileData?.user_id); // Fetch total stats for the user
      }
    };  
    const handleAddMatchClick = () => navigate('/submitMatch');

    const profileInfo = [
      { subtitle: 'Height', title: profileData?.height || 'N/A' },
      { subtitle: 'Age', title: profileData?.age || 'N/A' },
      { subtitle: 'Country', title: profileData?.country || 'N/A' },
      { subtitle: 'Preferred arm', title: profileData?.preferred_hand || 'N/A' },
      { subtitle: 'Preferred foot', title: profileData?.preferred_foot || 'N/A' },
      { subtitle: 'Total Matches', title: matchCount || '0' },
    ];

    const NavTab = ({ tab }) => (
      <div
        className={`nav-tab ${activeTab === tab ? 'active' : ''}`}
        onClick={() => handleTabClick(tab)}
      >
        {tab.charAt(0).toUpperCase() + tab.slice(1)}
      </div>
    );

    const InfoItem = ({ title, subtitle }) => (
      <div className="info-item">
        <span className="info-subtitle">{subtitle}</span>
        <span className="info-title">{title}</span>
      </div>
    );

    const MatchItem = ({ match, onClick }) => {
      const statusColor = match.status === 'confirmed' ? 'green' : 'yellow';
      const textColor = match.status === 'confirmed' ? 'white' : 'black'; // Dynamic text color
      const statusText = match.status === 'confirmed' ? 'Confirmed' : 'Under Review';
    
      return (
        <div
          className="match-item"
          onClick={() => onClick(match)} // Call the click handler
          style={{ cursor: 'pointer', position: 'relative' , background:'#1c1c1c84'}} // Indicate the item is clickable
        >
          <div className="match-venue">{match.venue}</div>
          <div className="match-details"  style={{ cursor: 'pointer', position: 'relative' , background:'none', top:'10px'}}>
            <span>{match.date_played}</span> | <span>{match.hour_slot}</span>
          </div>
          <div className="match-sport">{match.sport}</div>
    
          {/* Status indicator */}
          <div
            style={{
              position: 'absolute',
              bottom: '10px',
              right: '10px',
              backgroundColor: statusColor,
              color: textColor, // Apply dynamic text color
              borderRadius: '5px',
              padding: '5px 10px',
              fontSize: '12px',
              fontWeight: 'bold',
            }}
          >
            {statusText}
          </div>
        </div>
      );
    };  
    
      

    const deleteMatch = async (matchId) => {
      try {
        // Delete the match from Supabase
        const { error } = await supabase
          .from('Match_Details')
          .delete()
          .eq('id', matchId);
    
        if (error) {
          setError('Failed to delete match: ' + error.message);
          return;
        }

        const { data: matchDetails } = await supabase
      .from('Match_Details')
      .select('*')
      .eq('user_id', profileData?.user_id);
    
        // Update local state after successful deletion
        setMatches((prevMatches) => prevMatches.filter((match) => match.id !== matchId));
        setFilteredMatches((prevMatches) => prevMatches.filter((match) => match.id !== matchId));
        setRefreshKey((prevKey) => prevKey + 1);
        // Fetch authenticated user
        const { data: authData, error: authError } = await supabase.auth.getUser();
        if (authError || !authData?.user) {
          console.error('Error fetching user:', authError?.message || 'No user found');
          return;
        }
    
        const userId = authData.user.id;
    
        // Fetch highlights for the user
        // Fetch highlights for the user from Game_Stats table
const { data: highlightsData, error: highlightsError } = await supabase
.from('Game_Stats') // Correct table name
.select('highlights') // Select highlights column
.eq('user_id', userId) // Filter by user ID
.not('highlights', 'is', null) // Exclude NULL highlights
.neq('highlights', 'EMPTY'); // Exclude rows marked as EMPTY

if (highlightsError) {
console.error('Error fetching highlights:', highlightsError.message);
} else {
setHighlights(highlightsData || []); // Store valid highlights in state
}

      } catch (err) {
        setError('An unexpected error occurred while deleting the match.');
        console.error(err);
      }
    };
      

    const filterMatches = (sport) => {
      setSelectedFilter(sport);
      setFilterOpen(false); // Close the dropdown after selection
      if (sport === 'All') {
        setFilteredMatches(matches); // Show all matches
      } else {
        setFilteredMatches(matches.filter((match) => match.sport === sport)); // Filter by sport
      }
    };
      

    const renderGamificationBar = () => (
      <div className="gamification-bar">
        <h3>{rank}</h3>
        <div className="progress-bar">
          <div className="progress-fill" style={{ width: `${progress}%` }}></div>
        </div>
      </div>
    );

    const renderStats = () => {
      const isTotal = activeStatTab === 'Total';
    
      return (
        <div className="stats-container">
          <div className="stats-tabs">
            {['Total', 'Per 90'].map((tab) => (
              <div
                key={tab}
                className={`stats-tab ${activeStatTab === tab ? 'active' : ''}`}
                onClick={() => setActiveStatTab(tab)}
              >
                {tab}
              </div>
            ))}
          </div>
    
          {matchStats && (
            <div>
              <div className="stats-section" >
                <h3 className="section-title" style={{ color: '#168f77' }}>Shooting</h3>
                <div className="stat-row">
                  <span className="stat-label">Goals</span>
                  <span className="stat-value">
                    {isTotal
                      ? matchStats.goals
                      : (matchStats.gamesCount > 0
                          ? (matchStats.goals / matchStats.gamesCount).toFixed(2)
                          : 0)}
                  </span>
                </div>
                <div className="stat-row">
                  <span className="stat-label">Shots</span>
                  <span className="stat-value">
                    {isTotal
                      ? matchStats.shots
                      : (matchStats.gamesCount > 0
                          ? (matchStats.shots / matchStats.gamesCount).toFixed(2)
                          : 0)}
                  </span>
                </div>
                <div className="stat-row">
                  <span className="stat-label">Shots on Target</span>
                  <span className="stat-value">
                    {isTotal
                      ? matchStats.shotsOnTarget
                      : (matchStats.gamesCount > 0
                          ? (matchStats.shotsOnTarget / matchStats.gamesCount).toFixed(2)
                          : 0)}
                  </span>
                </div>
                <div className="stat-row">
                  <span className="stat-label">Assists</span>
                  <span className="stat-value">
                    {isTotal
                      ? matchStats.assists
                      : (matchStats.gamesCount > 0
                          ? (matchStats.assists / matchStats.gamesCount).toFixed(2)
                          : 0)}
                  </span>
                </div>
              </div>
    
              <div className="stats-section">
                <h3 className="section-title" style={{ color: '#168f77' }}>Possession</h3>
                <div className="stat-row">
                  <span className="stat-label">Total Dribbles</span>
                  <span className="stat-value">
                    {isTotal
                      ? matchStats.totalDribbles
                      : (matchStats.gamesCount > 0
                          ? (matchStats.totalDribbles / matchStats.gamesCount).toFixed(2)
                          : 0)}
                  </span>
                </div>
                <div className="stat-row">
                  <span className="stat-label">Successful Dribbles %</span>
                  <span className="stat-value">{matchStats.successfulDribblesPercentage}</span>
                </div>
              </div>
    
              <div className="stats-section">
                <h3 className="section-title" style={{ color: '#168f77' }}>Defending</h3>
                <div className="stat-row">
                  <span className="stat-label">Total Tackles</span>
                  <span className="stat-value">
                    {isTotal
                      ? matchStats.totalTackles
                      : (matchStats.gamesCount > 0
                          ? (matchStats.totalTackles / matchStats.gamesCount).toFixed(2)
                          : 0)}
                  </span>
                </div>
                <div className="stat-row">
                  <span className="stat-label">Tackles Won %</span>
                  <span className="stat-value">{matchStats.tacklesWonPercentage}</span>
                </div>
              </div>
            </div>
          )}
        </div>
      );
    };
    
    

    const renderMatchStats = () => (
      matchStats && (
        <div className="stats-section" style={{ 'background-color': '#1c1c1c84', padding:'10px', 'border-radius':'15px'}} >
          <h3 className="section-title"style={{ color: '#168f77' }}>Shooting</h3>
          <div className="stat-row">
            <span className="stat-label">Goals</span>
            <span className="stat-value">{matchStats.goals || 0}</span>
          </div>
          <div className="stat-row">
            <span className="stat-label">Shots</span>
            <span className="stat-value">{matchStats.shots || 0}</span>
          </div>
          <div className="stat-row">
            <span className="stat-label">Shots on Target</span>
            <span className="stat-value">{matchStats.shots_on_target || 0}</span>
          </div>
          <div className="stat-row">
            <span className="stat-label">Assists</span>
            <span className="stat-value">{matchStats.assists || 0}</span>
          </div>
    
          <h3 className="section-title"style={{ color: '#168f77' }}>Possession</h3>
          <div className="stat-row">
            <span className="stat-label">Total Dribbles</span>
            <span className="stat-value">{matchStats.Total_Dribbles || 0}</span>
          </div>
          <div className="stat-row">
            <span className="stat-label">Successful Dribbles</span>
            <span className="stat-value">{matchStats.succesful_dribbles || 0}</span>
          </div>
          <div className="stat-row">
            <span className="stat-label">Successful Dribbles %</span>
            <span className="stat-value">
              {matchStats.Successful_Dribbles_Percentage || '0%'}
            </span>
          </div>
    
          <h3 className="section-title" style={{ color: '#168f77' }}>Defending</h3>
          <div className="stat-row">
            <span className="stat-label">Total Tackles</span>
            <span className="stat-value">{matchStats.Total_Tackles || 0}</span>
          </div>
          <div className="stat-row">
            <span className="stat-label">Successful Tackles</span>
            <span className="stat-value">{matchStats.tackles_won || 0}</span>
          </div>
          <div className="stat-row">
            <span className="stat-label">Tackles Won %</span>
            <span className="stat-value">
              {matchStats.Tackles_Won_Percentage || '0%'}
            </span>
          </div>
        </div>
      )
    );        

    return (
      <div className="profile-container">
        {/* Profile Header */}
        <div className="profile-header">
          <h1 className="profile-name">{profileData?.name || 'User'}</h1>
          <Settings
            className="settings-icon"
            onClick={() => navigate('/settings')}
            style={{ cursor: 'pointer' }}
          />
        </div>
    
        {/* Navigation Tabs */}
        <div className="nav-tabs">
          {['profile', 'matches', 'stats', 'highlights'].map((tab) => (
            <NavTab key={tab} tab={tab} />
          ))}
        </div>
    
        {/* Content Section */}
        <div className="content-section">
          {activeTab === 'profile' && (
            <div>
              {renderGamificationBar()}
              <div className="info-card">
                {profileInfo.map((info, index) => (
                  <InfoItem key={index} title={info.title} subtitle={info.subtitle} />
                ))}
              </div>
              <div className="add-match-container">
                <button className="add-match-btn" onClick={handleAddMatchClick}>
                  Add Match
                </button>
              </div>
            </div>
          )}
    
    {activeTab === 'matches' && !selectedMatch && (
  <div className="content-section">
    <div className="filter-dropdown" onClick={() => setFilterOpen(!filterOpen)}>
      <span>{selectedFilter}</span>
      <ChevronDown className={`chevron ${filterOpen ? 'open' : ''}`} />
      {filterOpen && (
        <div className="filter-options">
          {['All', 'Football', 'Basketball'].map((sport) => (
            <div
              key={sport}
              className="filter-option"
              onClick={() => filterMatches(sport)}
            >
              {sport}
            </div>
          ))}
                </div>
              )}
            </div>
            <div className="matches-list">
  {filteredMatches.length > 0 ? (
    filteredMatches.map((match) => (
      <MatchItem
        key={match.id}
        match={match}
        onClick={handleMatchClick} // Pass the click handler here
      />
    ))
  ) : (
    <p style={{ color: 'white', textAlign: 'center', marginTop: '20px' }}>
      No matches found.
    </p>
  )}
</div>

          </div>
        )}
    
        {/* Match Details View */}
        {activeTab === 'matches' && selectedMatch && (
          <div className="content-section">
            <div className="title-bar">
              <div>
                <h2>
                  {selectedMatch.venue} - {selectedMatch.date_played}
                </h2>
                <h3>{selectedMatch.sport}</h3>
              </div>
              <button className="back-link" onClick={() => setSelectedMatch(null)}>
                Back to Matches
              </button>
            </div>
            {renderMatchStats()}
          </div>
        )}
    
          {activeTab === 'stats' && renderStats()}
    
          {activeTab === 'highlights' && highlights.length > 0 && (
  <div className="highlights-list">
    {highlights.map((highlight, index) => (
      <div key={index} className="highlight-card">
        <div className="highlight-details">
          <div><strong>Venue:</strong> {highlight.venue}</div>
          <div><strong>Pitch:</strong> {highlight.pitch}</div>
          <div><strong>Date:</strong> {highlight.datePlayed}</div>
          <div><strong>Time:</strong> {highlight.hourSlot}</div>
          <div><strong>Sport:</strong> {highlight.sport}</div>
        </div>
        <a
          href={highlight.highlights}
          target="_blank"
          rel="noopener noreferrer"
          className="highlight-link"
        >
          Watch Highlight
        </a>
      </div>
    ))}
  </div>
)}
{activeTab === 'highlights' && highlights.length === 0 && (
  <p style={{ color: 'white', textAlign: 'center', marginTop: '20px' }}>
    No highlights available.
  </p>
)}

        </div>
      </div>
    );
  }