import React, { useState, useEffect } from 'react';
import supabase from './supabaseClient';

export default function ResetPassword() {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    const handleAuthStateChange = async () => {
      supabase.auth.onAuthStateChange(async (event, session) => {
        if (event === 'PASSWORD_RECOVERY') {
          // Clear errors when reloaded
          setError('');
        }
      });
    };
    handleAuthStateChange();
  }, []);

  const validatePassword = (password) => {
    // Trim whitespace to avoid invisible errors
    const trimmedPassword = password.trim();
  
    // Check for the correct pattern
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/;
  
    // Log trimmed password for debugging
    console.log("Validating password:", trimmedPassword);
  
    return regex.test(trimmedPassword);
  };
  

  const handleResetPassword = async () => {
    setError('');
    if (newPassword !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }

    if (!validatePassword(newPassword)) {
      setError(
        'Password must include lowercase, uppercase letters, digits, and symbols (minimum 8 characters).'
      );
      return;
    }

    const { data, error } = await supabase.auth.updateUser({ password: newPassword });

    if (data) {
      setSuccessMessage('Password updated successfully! You can now log in.');
      setNewPassword('');
      setConfirmPassword('');
    }

    if (error) {
      setError('There was an error updating your password: ' + error.message);
    }
  };

  return (
    <div className="form-container" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px', borderRadius: '15px', background: 'linear-gradient(145deg, #1e3c72, #2a5298)', boxShadow: '0 4px 10px rgba(0, 0, 0, 0.5)', maxWidth: '400px', margin: 'auto' }}>
      <h2 style={{ color: 'white', marginBottom: '20px' }}>Reset Password</h2>
      <input
        type={showPassword ? 'text' : 'password'}
        placeholder="New Password"
        value={newPassword}
        onChange={(e) => setNewPassword(e.target.value)}
        style={{ marginBottom: '10px', padding: '10px', width: '100%', borderRadius: '5px', border: '1px solid #ccc', fontSize: '16px' }}
      />
      <input
        type={showPassword ? 'text' : 'password'}
        placeholder="Confirm New Password"
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
        style={{ marginBottom: '10px', padding: '10px', width: '100%', borderRadius: '5px', border: '1px solid #ccc', fontSize: '16px' }}
      />
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
        <input
          type="checkbox"
          checked={showPassword}
          onChange={() => setShowPassword(!showPassword)}
          style={{ marginRight: '10px' }}
        />
        <label style={{ color: 'white', fontSize: '14px' }}>Show Password</label>
      </div>
      <p style={{ color: 'white', fontSize: '14px', marginBottom: '20px', textAlign: 'center' }}>
        <strong>Password Requirements:</strong> Must include lowercase, uppercase letters, digits, and symbols (minimum 8 characters).
      </p>
      {error && <p style={{ color: 'red', fontSize: '14px', marginBottom: '10px' }}>{error}</p>}
      {successMessage && <p style={{ color: 'green', fontSize: '14px', marginBottom: '10px' }}>{successMessage}</p>}
      <button
        onClick={handleResetPassword}
        style={{ background: '#4CAF50', color: 'white', padding: '10px 20px', border: 'none', borderRadius: '5px', cursor: 'pointer', fontSize: '16px' }}
      >
        Reset Password
      </button>
    </div>
  );
}
