import React, { useState, useEffect } from 'react';
import supabase from './supabaseClient';
import { useNavigate } from 'react-router-dom';
import './index.css';

export default function CreateProfile() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    age: '',
    height: '',
    preferred_hand: '',
    preferred_foot: '',
    country: '',
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [userId, setUserId] = useState('');
  const [isProfileExists, setIsProfileExists] = useState(false);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const { data: sessionData, error: sessionError } = await supabase.auth.getSession();
        if (sessionError || !sessionData.session) {
          setError('User not logged in.');
          navigate('/login');
          return;
        }

        const user = sessionData.session.user;
        if (!user.email_confirmed_at) {
          setError('Email not verified. Please verify your email first.');
          navigate('/confirm-email');
          return;
        }

        setFormData((prev) => ({ ...prev, email: user.email }));
        setUserId(user.id);

        // Check if the profile already exists
        const { data: existingUser, error: fetchError } = await supabase
          .from('Users')
          .select('*') // Adjust columns as per your database schema
          .eq('user_id', user.id)
          .single();

        if (existingUser) {
          // If profile exists, redirect to profile page
          setIsProfileExists(true);
          navigate('/profile');
        }
      } catch (err) {
        setError('Error fetching user details: ' + err.message);
      }
    };

    fetchUserDetails();
  }, [navigate]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data: existingUser, error: fetchError } = await supabase
        .from('Users')
        .select('*')
        .eq('user_id', userId)
        .single();

      if (existingUser) {
        // Update the existing profile
        const { error: updateError } = await supabase
          .from('Users')
          .update({
            name: formData.name,
            age: formData.age,
            height: formData.height,
            preferred_hand: formData.preferred_hand,
            preferred_foot: formData.preferred_foot,
            country: formData.country,
          })
          .eq('user_id', userId);

        if (updateError) throw updateError;

        setSuccess('Profile updated successfully!');
      } else {
        // Insert the new profile
        const { error: insertError } = await supabase.from('Users').insert([
          {
            user_id: userId,
            email: formData.email,
            name: formData.name,
            age: formData.age,
            height: formData.height,
            preferred_hand: formData.preferred_hand,
            preferred_foot: formData.preferred_foot,
            country: formData.country,
          },
        ]);

        if (insertError) throw insertError;

        setSuccess('Profile created successfully!');
      }

      navigate('/profile');
    } catch (err) {
      setError('Error creating profile: ' + err.message);
    }
  };

  if (isProfileExists) {
    return (
      <div className="form-container">
        <h2>Profile Already Exists</h2>
        <p>You already have a profile. Redirecting to your profile page...</p>
      </div>
    );
  }

  return (
    <div className="form-container">
      <a href="/" className="back-link">← Back</a>

      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">Name</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            required
            disabled
          />
        </div>

        <div className="form-group">
          <label htmlFor="age">Age</label>
          <input
            type="number"
            id="age"
            name="age"
            value={formData.age}
            onChange={handleInputChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="height">Height</label>
          <input
            type="number"
            id="height"
            name="height"
            value={formData.height}
            onChange={handleInputChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="preferred_hand">Preferred Hand</label>
          <select
            id="preferred_hand"
            name="preferred_hand"
            value={formData.preferred_hand}
            onChange={handleInputChange}
            required
          >
            <option value="">Select</option>
            <option value="Right">Right</option>
            <option value="Left">Left</option>
            <option value="Both">Both</option>
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="preferred_foot">Preferred Foot</label>
          <select
            id="preferred_foot"
            name="preferred_foot"
            value={formData.preferred_foot}
            onChange={handleInputChange}
            required
          >
            <option value="">Select</option>
            <option value="Right">Right</option>
            <option value="Left">Left</option>
            <option value="Both">Both</option>
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="country">Country</label>
          <input
            type="text"
            id="country"
            name="country"
            value={formData.country}
            onChange={handleInputChange}
            required
          />
        </div>

        <button type="submit" className="signup-btn">Create Profile</button>
      </form>

      {error && <p className="error-message">{error}</p>}
      {success && <p className="success-message">{success}</p>}
    </div>
  );
}
