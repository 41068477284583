// src/AdminRoute.js
import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import supabase from '../supabaseClient';

const AdminRoute = ({ children }) => {
  const [isAdmin, setIsAdmin] = useState(null);

  useEffect(() => {
    const checkAdmin = async () => {
      const { data: userData, error } = await supabase.auth.getUser();

      if (error || !userData || !userData.user) {
        setIsAdmin(false);
        return;
      }

      const { data: userProfile, error: profileError } = await supabase
        .from('Users')
        .select('admin_Pass')
        .eq('user_id', userData.user.id)
        .single();

      if (profileError || !userProfile || !userProfile.admin_Pass?.trim()) {
        setIsAdmin(false);
      } else {
        setIsAdmin(true);
      }
    };

    checkAdmin();
  }, []);

  if (isAdmin === null) {
    return <div>Loading...</div>; // Show a loader while checking admin status
  }

  return isAdmin ? children : <Navigate to="/" />;
};

export default AdminRoute;
