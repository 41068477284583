import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import supabase from './supabaseClient'; // Ensure you import your Supabase client
import './ConfirmEmail.css';

export default function ConfirmEmail() {
  const navigate = useNavigate();

  useEffect(() => {
    const checkEmailConfirmation = async () => {
      const { data } = await supabase.auth.getSession();

      if (data?.session?.user?.email_confirmed_at) {
        // If the user's email is confirmed, redirect to CreateProfile
        navigate('/create-profile');
      }
    };

    checkEmailConfirmation();
  }, [navigate]);

  return (
    <div className="confirm-email-container">
      <div className="logo-container">
        {/* Use the public folder path directly */}
        <img
          src="/Logo_Statbox.jpg"
          alt="Loading Logo"
          className="loading-logo"
        />
      </div>
      <h1>Check Your Email</h1>
      <p>
        A confirmation email has been sent to your inbox. Please confirm your
        email address to proceed.
      </p>
    </div>
  );
}
